var config = {
    apiKey: "AIzaSyA7HAuoJ7xAs42ATD5ExrKrf6f1JTascrs",
    authDomain: "justchat-b28c7.firebaseapp.com",
    databaseURL: "https://justchat-b28c7-default-rtdb.firebaseio.com",
    projectId: "justchat-b28c7",
    storageBucket: "justchat-b28c7.appspot.com",
    messagingSenderId: "893721470873",
    appId: "1:893721470873:web:fb8ea01ee608166f601508",
    measurementId: "G-RK2GT4BB4R"
}

export default config